import React from 'react';
import {TableProps} from '@backstage/core-components';
import {CatalogIndexPage, CatalogTableRow} from '@backstage/plugin-catalog';
import {
  EntityKindPicker,
  EntityTypePicker,
  EntityOwnerPicker,
  EntityLifecyclePicker,
  EntityTagPicker,
  EntityProcessingStatusPicker,
  UserListPicker,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import {IconButton, Tooltip, withStyles} from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

export const CustomCatalogIndexPage = () => {
  const {toggleStarredEntity, isStarredEntity} = useStarredEntities();

  const actions: TableProps<CatalogTableRow>['actions'] = [
    {
      position: 'auto',
      action(rowData) {
        return {
          icon: () => {
            return (
              <IconButton aria-label="favorite" color="inherit">
                <Tooltip
                  title={
                    isStarredEntity(rowData.entity)
                      ? 'Remove from favorites'
                      : 'Add to favorites'
                  }
                >
                  {isStarredEntity(rowData.entity) ? (
                    <YellowStar />
                  ) : (
                    <StarBorder />
                  )}
                </Tooltip>
              </IconButton>
            );
          },
          onClick: (_event, data) => {
            if (!Array.isArray(data)) {
              toggleStarredEntity(data.entity);
            }
          },
        };
      },
    },
  ];

  return (
    <CatalogIndexPage
      actions={actions}
      filters={
        <>
          <EntityKindPicker
            allowedKinds={['component', 'group', 'user', 'system']}
          />
          <EntityTypePicker />
          <EntityOwnerPicker />
          <EntityLifecyclePicker />
          <EntityTagPicker />
          <EntityProcessingStatusPicker />
          <UserListPicker />
        </>
      }
    />
  );
};
