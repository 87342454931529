import {
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  ANNOTATION_LOCATION,
  stringifyEntityRef,
  Entity,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  EntityRefLinks,
  getEntityRelations,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import CachedIcon from '@material-ui/icons/Cached';
import Alert from '@material-ui/lab/Alert';
import React, {useCallback} from 'react';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
  MarkdownContent,
} from '@backstage/core-components';
import {alertApiRef, useApi} from '@backstage/core-plugin-api';
import {GroupEntity} from '../../model-extensions/group';

const CardTitle = (props: {title: string}) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit" />
    <Box ml={1}>{props.title}</Box>
  </Box>
);

function getGroupResponsibilities(
  group: GroupEntity,
  teamInfo: Entity | undefined,
): string {
  if (!teamInfo) {
    return group.spec.profile?.responsibilities
      ? `# Зона ответственности команды:\n\n${group.spec.profile.responsibilities}`
      : '';
  }

  return teamInfo?.spec?.responsibilities
    ? `# Зона ответственности команды:\n\n${teamInfo.spec.responsibilities}`
    : '';
}

/** @public */
export const GroupProfileCard = (props: {variant?: InfoCardVariants}) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const {entity: group} = useEntity<GroupEntity>();
  const {entities: relatedTeamInfo} = useRelatedEntities(group, {
    kind: 'TeamInfo',
  });
  const teamInfo = relatedTeamInfo ? relatedTeamInfo[0] : undefined;

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({message: 'Refresh scheduled', severity: 'info'});
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const {
    metadata: {name, description, annotations},
    spec: {profile},
  } = group;

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const displayName = profile?.displayName ?? name;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '#';
  const responsibilities = getGroupResponsibilities(group, teamInfo);

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {allowRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
            >
              <CachedIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar
            displayName={displayName}
            picture={profile?.picture || (teamInfo?.spec?.picture as string)}
          />
        </Grid>
        <Grid item md={10} xl={11}>
          <List>
            {responsibilities && (
              <ListItem>
                <MarkdownContent content={responsibilities} />
              </ListItem>
            )}
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            {parentRelations.length ? (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Parent Group">
                    <AccountTreeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <EntityRefLinks
                    entityRefs={parentRelations}
                    defaultKind="Group"
                  />
                </ListItemText>
              </ListItem>
            ) : null}

            {childRelations.length ? (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Child Groups">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <EntityRefLinks
                    entityRefs={childRelations}
                    defaultKind="Group"
                  />
                </ListItemText>
              </ListItem>
            ) : null}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
