import {
  ANNOTATION_LOCATION,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCard,
} from '@backstage/core-components';
import {alertApiRef, useApi} from '@backstage/core-plugin-api';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {AboutContent} from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {CardContent, IconButton, makeStyles} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import React, {useCallback} from 'react';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
    padding: '0 !important',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export const EntityCard = () => {
  const {entity} = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);

  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl,
  };
  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(entity));
    alertApi.post({message: 'Refresh scheduled', severity: 'info'});
  }, [catalogApi, alertApi, entity]);

  const cardContentClass = useStyles();
  return (
    <InfoCard
      cardClassName={cardContentClass.gridItemCard}
      variant="gridItem"
      title="About"
      subheader={<HeaderIconLinkRow links={[viewInSource]} />}
      action={
        <>
          {allowRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
            >
              <CachedIcon />
            </IconButton>
          )}
        </>
      }
    >
      <CardContent className={cardContentClass.gridItemCardContent}>
        <AboutContent entity={entity} />
      </CardContent>
    </InfoCard>
  );
};
