import React, {useState, useEffect, useMemo} from 'react';

import {configApiRef, useApi} from '@backstage/core-plugin-api';
import {
  Content,
  PageWithHeader,
  TableColumn,
  InfoCard,
  Table,
} from '@backstage/core-components';
import {catalogApiRef} from '@backstage/plugin-catalog-react';
import GroupIcon from '@material-ui/icons/Group';

import {SearchBar} from '@backstage/plugin-search-react';
import {makeStyles} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {Entity} from '@backstage/catalog-model';

const useStyle = makeStyles({
  infoCard: {
    marginTop: '1rem',
    backgroundColor: '#3876691f',
  },
  clickable: {
    color: '#1F5493',
  },
  description: {
    paddingBottom: '1rem',
  },
  owner: {
    marginRight: '0.5rem',
  },
  subheader: {
    display: 'grid',
    gridTemplateColumns: '80px 35px 1fr',
    alignItems: 'flex-end',
  },
});

interface System {
  id?: string;
  name?: string;
  owner?: string;
  description?: string;
  namespace: string;
  ownedComponents?: Entity[];
}

// Define the columns for the table
const columns: TableColumn<Entity>[] = [
  {
    title: 'Name',
    field: 'metadata.name',
    render: (entity: Entity) => {
      return (
        <NavLink
          to={`/catalog/${entity.metadata.namespace}/component/${entity.metadata.name}`}
          style={{color: '#1F5493'}}
        >
          {entity.metadata.name}
        </NavLink>
      );
    },
  },
  {title: 'Owner', field: 'spec.owner'},
  {title: 'Type', field: 'spec.type'},
  {title: 'Lifecycle', field: 'spec.lifecycle'},
  {title: 'Description', field: 'metadata.description'},
];

export const SystemsList = () => {
  const classes = useStyle();
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  const catalogApi = useApi(catalogApiRef);

  const [components, setComponents] = useState<Map<string, Entity>>(new Map());

  useEffect(() => {
    // Fetch components from the catalog
    const fetchData = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: {kind: 'Component'},
        });

        const componentsMap = new Map<string, Entity>();

        for (const item of response.items) {
          componentsMap.set(item.metadata.name.toLocaleLowerCase(), item);
        }

        setComponents(componentsMap);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch components:', error);
      }
    };

    fetchData();
  }, [catalogApi]);

  const [systems, setSystems] = useState<System[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch systems from the catalog
    const fetchData = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: {kind: 'System'},
        });

        setSystems(
          response.items.map(item => {
            const ownedComponents = item.relations?.filter(
              rel => rel.type === 'hasPart',
            );

            const relatedEntities: Entity[] = [];
            if (ownedComponents) {
              for (const component of ownedComponents) {
                if ('target' in component) {
                  const name = (component.target as {name?: string}).name ?? '';

                  const owned = components.get(name.toLocaleLowerCase());

                  if (owned) {
                    relatedEntities.push(owned);
                  }
                }
              }
            }

            return {
              name: item.metadata.name,
              owner:
                item.spec?.owner?.toLocaleString() ??
                item.metadata.owner?.toString() ??
                '',
              id: item.metadata.uid,
              description: item.metadata.description,
              namespace: item.metadata.namespace || 'default',
              ownedComponents: relatedEntities,
            };
          }),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch systems:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [catalogApi, components]);

  const [search, setSearch] = useState('');

  const filteredSystems = useMemo(() => {
    return systems.filter(
      system =>
        system.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        system.description
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        system.owner?.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }, [systems, search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageWithHeader title={`${orgName} Systems`} themeId="home">
      <Content>
        <SearchBar
          placeholder="Type system's name, owner or description"
          value={search}
          onChange={v => setSearch(v)}
        />

        {filteredSystems.map(system => (
          <InfoCard
            key={system.id}
            className={classes.infoCard}
            titleTypographyProps={{variant: 'h2'}}
            title={
              <NavLink
                className={classes.clickable}
                to={`/catalog/${system.namespace}/system/${system.name}`}
              >
                {system.name}
              </NavLink>
            }
            subheader={
              system.owner ? (
                <div className={classes.subheader}>
                  <div className={classes.owner}>Owner:</div>

                  <GroupIcon />
                  <NavLink
                    className={classes.clickable}
                    to={`/catalog/${system.namespace}/group/${system.owner}`}
                  >
                    {`${system.owner ?? ''}`}
                  </NavLink>
                </div>
              ) : undefined
            }
          >
            <div className={classes.description}>{system.description}</div>
            {system.ownedComponents?.length ? (
              <Table
                filters={undefined}
                subtitle={`Components owned by ${system.name}`}
                data={system.ownedComponents}
                columns={columns}
              />
            ) : (
              <div>This system has no components</div>
            )}
          </InfoCard>
        ))}
      </Content>
    </PageWithHeader>
  );
};
